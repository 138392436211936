import { useEffect } from "react";
import { Link } from "react-router-dom";

function Home() {
  useEffect(() => {
    if (window.location.pathname !== "/home") {
      window.location.href = "https://apps.apple.com/app/only-widget/id1621293743";
    }
  }, []);

  return (
    <div
      style={{
        backgroundColor: "rgb(173, 207, 255)",
        position: 'absolute',
        top: 0,
        left: 0,
        height: "100%",
        width: "100%",
      }}
      className="font-face-gm"
    >
      <h1 style={{paddingTop: '20%'}}>Only</h1>
      <h4 style={{marginTop: -16, marginBottom: 40}}>The friend group live pic widget</h4>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://apps.apple.com/app/only-widget/id1621293743"
      >
        <img
          src="/appstore_badge.svg"
          alt="Download on the App Store"
          style={{
            height: "auto",
            width: 180,
            marginTop: 10,
            cursor: "pointer",
          }}
        />
      </a>
      <div style={footerStyle}><Link style={{paddingRight: 20, textAlign: "center",}} to="/terms">Terms</Link><Link style={{paddingRight: 20, textAlign: "center",}} to="/privacy">Privacy</Link></div>
    </div>
  );
}

const footerStyle = {
  textAlign: "center",
  justifyContent: 'center',
  alignItems: 'center',
  padding: "20px",
  position: "fixed",
  left: "0",
  bottom: "0",
  height: "20px",
  width: "100%"
};

export default Home;
