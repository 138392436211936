import background from "../assets/onlyfriends_bg.png";

function Privacy() {
  return (
    <div
      style={{
        backgroundColor: "rgb(173, 207, 255)",
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "50%",
          textAlign: "left",
          paddingLeft: "25%",
          paddingRight: "25%",
          paddingTop: "10%",
          paddingBottom: "10%",
        }}
        className="font-face-gm"
      >
        <p>PRIVACY POLICY</p>
        <p>Effective date: May 12 2022</p>
        Only is an iOS app and widget that lets you share pictures with your
        friend groups, right on your home screen. When you send a picture using
        Only, it will magically appear on your friends' home screen, without
        them having to open the app. Especially because Only is designed to be
        used with your closest friends, we care deeply about keeping your data
        private and secure.
        <br></br><br></br>
        Below, we outline what data we collect and how we use it. 
        <br></br><br></br>
        Personal Information
        <br></br><br></br>
        When you sign up for an account on Only, you are asked to enter your
        phone number and name. This data is used to identify you to other Only
        and makes it easy for your friends to find your account on the service.
        <br></br><br></br>
        Contacts data
        <br></br><br></br>
        After signing up, Only asks for access to your address book. This
        makes it easy to add your friends to your Only. First and foremost, we
        never save your contacts and never send text messages to your friends
        without your explicit permission. Some apps abuse their access to your
        contacts, so we want to be sure your friends' data always stays in your
        hands, not ours.
        <br></br><br></br>
        When you add a friend on Only, we first look up their phone number in
        our records to see if they already have an account. If they don't, then
        we store their phone number without any personally identifiable
        information attached (no name, no profile picture, no nothing). Linking
        their number to your account means that once they sign up, they can
        immediately accept your friend request. We do not save the phone numbers
        of any users you don't explicitly add as a friend.
        <br></br><br></br>
        To invite a new user to the app, Only gives you the option of sending
        them a text message with a link to install the app. We do not
        automatically message your friends on your behalf. Instead, you have to
        send the message yourself. This means you're always in control of what
        messages get sent to your friends. Everyone hates when an app secretly
        sends messages to all their Contacts, us included.
        <br></br><br></br>
        Once you add a friend, Only looks up the name and profile picture you
        have saved for that person in your Contacts. This information is then
        used to identify your friend throughout the app. It's important to note
        that this data never leaves your phone and never gets saved to our
        databases. Photos Sending photos to your friends is incredibly personal,
        so we work hard to keep this data private and secure. When you send a
        new photo on Only, it will be sent to all friends you have added on
        the app. They will be able to see this photo in real-time in their
        Only widget. The photo will also be added to yours and your friends'
        histories.
        <br></br><br></br>
        Once you've sent a photo, it isn't possible to delete it from other
        user's phones, so be careful with what photos you choose to send. Think
        of sending a Only like sending a text message — once it has been
        delivered, there isn't any way to take it back. You can, however, delete
        photo from your own phone. If you choose to delete a photo, it will
        remove it from your phone, but your friends will still be able to see
        the photo.
        <br></br><br></br>
        Photos you've sent in the past are not visible to new people you add as
        friends. For example, if you add a photo to your Only last week and
        add a new friend today, your new friend won't be able to see that photo
        you sent last week. They will only see new photos you've added to your
        Only since being friends with them. 
        <br></br><br></br>
        Changes to this policy
        <br></br><br></br>
        Only can change this policy at any time. If this policy is changed,
        all users will receive a message in the app with the new policy.
        <br></br><br></br>
        Downloading or deleting your data
        <br></br><br></br>
        To download your data or deactivate your account, please email
        support@onlyfriendswidget.com and include the phone number you used to register
        your account.
      </div>
    </div>
  );
}

export default Privacy;
