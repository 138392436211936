import mixpanel from "mixpanel-browser";
import { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import background from "../assets/onlyfriends_bg.png";

function InviteCodePage() {
  const [copied, setCopied] = useState(false);

  const inputRef = useRef(null);
  const params = useParams();

  const copyCodeToClipboard = async () => {
    inputRef.current.select();
    if (navigator.clipboard) {
      try {
        await navigator.clipboard.writeText(params.code);
        setCopied(true);
        return;
      } catch (_) {}
    }
    document.execCommand("copy");
    setCopied(true);
  };

  return (
    <div
      style={{
        backgroundColor: "rgb(173, 207, 255)",
        position: "absolute",
        top: 0,
        left: 0,
        height: "100%",
        width: "100%",
        backgroundSize: "cover",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: 30,
        boxSizing: "border-box",
      }}
      className="font-face-gm"
    >
      <h1 style={{ margin: 10 }}>
        Got an invite to join an Only Widget group?
      </h1>
      <h2 style={{ margin: 0, marginBottom: 20, fontWeight: "600" }}>
        Download the app and use this code below when signing up
      </h2>
      <input
        value={params.code}
        style={{
          width: 170,
          fontSize: 20,
          textAlign: "center",
          borderRadius: 8,
          border: "1px dashed white",
          paddingTop: 13,
          paddingBottom: 12,
          marginBottom: 0,
          backgroundColor: "rgba(255, 255, 255, 0.05)",
          color: "#FFFFFF",
          cursor: "pointer",
        }}
        spellCheck={false}
        readOnly
        ref={inputRef}
        onClick={() => copyCodeToClipboard()}
        className="font-face-gm"
      />
      {copied && (
        <div style={{ fontSize: 14, opacity: 0.8, marginTop: 5 }}>
          Copied to clipboard!
        </div>
      )}
      <a
        target="_blank"
        rel="noreferrer"
        href="https://apps.apple.com/app/only-widget/id1621293743"
        onClick={() => {
          copyCodeToClipboard();
          mixpanel.track("Clicked app link", {
            location: "join",
          });
        }}
      >
        <img
          src="/appstore_badge.svg"
          alt="Download on the App Store"
          style={{
            height: "auto",
            width: 180,
            marginTop: 60,
            cursor: "pointer",
          }}
        />
      </a>
      <div style={{ fontSize: 20, marginTop: 30 }}>
        <div>Already got the app?</div>
        <a style={{ color: "white" }} href={`onlywidget://join/${params.code}`}>
          Click here to join the group
        </a>
      </div>
    </div>
  );
}

export default InviteCodePage;
